// Packages
import React, { useRef, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

// Assets
import BaseStyles from '../../styles/base'
import theme from '../../styles/theme'

// Styled components
import { StyledAppLayout, StyledMain, StyledContent, StyledWAButtonContainer } from './styledComponents'

// Components
import Footer from '../../components/presentational/Footer'
import WAButton from '../../components/presentational/WAButton'
import Header from '../../components/presentational/Header'

const AppLayout = ({ children }) => {  
  const [footerHeigth, setFooterHeigth] = useState(0)
  const footerRef = useRef(null)  

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeigth(footerRef.current.offsetHeight)
    }
  }, [footerRef])

  return (
    <ThemeProvider theme={theme}>      
      <BaseStyles />      
      <StyledAppLayout>
        <Header />
        <StyledMain minHeight={footerHeigth}>
          <StyledContent>            
            {children}
          </StyledContent>
        </StyledMain>
        <Footer ref={footerRef} />
        <StyledWAButtonContainer>
          <WAButton />
        </StyledWAButtonContainer>
      </StyledAppLayout>   
    </ThemeProvider>
  )
}

export default AppLayout
