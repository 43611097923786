// Assets
import headerOptions from './headerOptions'
import sliderCommonSettings from './sliderCommonSettings'
import servicesList from './servicesList'

export default {
  headerOptions,
  sliderCommonSettings,
  servicesList
}
