const theme = {
  colors: {
    white: '#fff',
    black: '#093046',
    blue: '#0078B7',
    ligthBlue: '#542405',
    primary: '',
    secondary: '',
    tertiary: '',
    quaternary: '',
    font: '#3B3B3B',
    background: '',
    transparent: 'transparent',
    xDarkGray: "#666",
    xxDarkGray: "#333",
  },
  breakpoints: {
    min: 320,
    xxxxSmall: 375,
    xxxSmall: 410,
    xxSmall: 480,
    xSmall: 576,
    small: 640,
    medium: 768,
    large: 960,
    xLarge: 1024,
    xxLarge: 1200,
    xxxLarge: 1440,
    xxxxLarge: 1680,
    max: 1920
  },
  font: {
    lineHeights: {
      small: 1.3,
      medium: 1.5,
      large: 1.7
    },
    sizes: {
      small: 14,
      medium: 15,
      large: 16
    },
    weights: {
      normal: 400,
      semibold: 500,
      bold: 700
    }
  }
}

theme.colors.primary = theme.colors.black
theme.colors.secondary = theme.colors.blue
theme.colors.tertiary = theme.colors.ligthBlue
theme.colors.quaternary = theme.colors.white
theme.colors.background = theme.colors.white

export default theme
