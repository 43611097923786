// Packages
import React from 'react'

// Styled components
import { StyledWAButton, StyledWAIcon } from './styledComponents'

const WAButton = () => {
  return (
    <StyledWAButton href='https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n' target='_blank'>
      <StyledWAIcon />
    </StyledWAButton>
  )
}

export default WAButton
