// Packages
import styled from 'styled-components'
import { Link } from 'gatsby'

export const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 2px #00000029;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: ${props => props.theme.breakpoints.xxxLarge}px;
    width: 90%;
  }
`

export const StyledOptions = styled.div`

`

export const StyledOption = styled(Link)`
  text-transform: capitalize;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    font-size: 1.1rem;
  }  
`

export const StyledPipe = styled.span`
  margin: 0 0.5rem;

  @media (min-width: ${props => props.theme.breakpoints.xxxxSmall}px) { 
    margin: 0 1rem;
  }
`

export const StyledLogoContainer = styled(Link)``

export const StyledLogo = styled.img`
  height: 100%;
  max-width: 8rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 10rem;
  }
`
