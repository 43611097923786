// Packages
import React, { forwardRef } from 'react'

// Styled components
import { StyledFooter, StyledContent, StyledP, StyledA, StyledLink, StyledStrong, StyledSocialMedia, StyledInstagramIcon, StyledFacebookIcon, StyledPrivacySocialMediaContainer } from './styledComponents'

const Footer = forwardRef((props, ref) => {
  return (
    <StyledFooter ref={ref || { current: null }} {...props}>
      <StyledContent>
        <StyledP>
          Av. Alvaro Obregón 720, Colonia Primero de Mayo, Ciudad Madero. Tamaulipas. Informes: <StyledA href='tel:+528333671739'>833 367 1739</StyledA>
        </StyledP>
        <StyledPrivacySocialMediaContainer>
          <StyledLink to='/aviso-de-privacidad'>Aviso de privacidad</StyledLink>
          <StyledSocialMedia>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.facebook.com/laboratoriosalvarezmx'
            >
              <StyledFacebookIcon />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/laboratoriosalvarezmx/'
            >
              <StyledInstagramIcon />
            </a>
          </StyledSocialMedia>
        </StyledPrivacySocialMediaContainer>
        <StyledP>
          <StyledStrong>
            Todos los derechos reservados a Laboratorio Álvarez 2020.
          </StyledStrong>
        </StyledP>
      </StyledContent>
    </StyledFooter>
  )
})

export default Footer