// Packages
import React, { memo } from 'react'

// Styled components
import { StyledHeader, StyledContainer, StyledOption, StyledOptions, StyledPipe, StyledLogoContainer, StyledLogo } from './styledComponents'

// Assets
import useData from '../../../hooks/useData'
import Logo from '../../../data/assets/img/logo.jpeg'

const Header = () => {  
  const { constants: { headerOptions } } = useData()

  const optionsRendered = headerOptions.map((option, index) => {
    return (
      <StyledOption to={option.to} key={option.label + '-' + index}>
        {option.label}{index < headerOptions.length - 1 && <StyledPipe>|</StyledPipe>}
      </StyledOption>
    )
  })

  return (
    <StyledHeader>
      <StyledContainer>        
        <StyledLogoContainer to='/'>
          <StyledLogo src={Logo} />
        </StyledLogoContainer>        
        <StyledOptions>
          {optionsRendered}
        </StyledOptions>
      </StyledContainer>
    </StyledHeader>
  )
}

export default memo(Header)
