// Packages
import styled from 'styled-components'

export const StyledAppLayout = styled.main`
  display: block;
  min-width: ${props => props.theme.breakpoints.min}px;
`

export const StyledMain = styled.main`
  display: flex;
  height: auto;
  justify-content: center;
  min-height: calc(100vh - ${props => props.minHeight}px);

  position: static;
  top: 0;
  width: auto;
`

export const StyledContent = styled.div`
  height: auto;
  max-width: ${props => props.theme.breakpoints.xxxLarge}px;
  min-width: ${props => props.theme.breakpoints.min}px;
  position: relative;
  width: 100%;
`

export const StyledWAButtonContainer = styled.div`
	bottom: 1rem;
  right: 1rem;
  position: fixed;
`
