const servicesList = [
  {
    title: 'Paquete básico 1',
    description: 'Biometría hemática (BH), química sanguínea sencilla/ 3 parámetros (glucosa, urea, creatinina), examen general de orina (EGO)'
  },
  {
    title: 'Check up básico',
    description: 'Glucosa, colesterol, triglicéridos y biometría hemática'
  },
  {
    title: 'Paquete básico 2',
    description: 'Biometría hemática (BH), química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), examen general de orina (EGO)'
  },
  {
    title: 'Check up diabetes',
    description: 'Hemoglobina, glucosilada y glucosa'
  },
  {
    title: 'Paquete básico 3',
    description: 'Biometría hemática (BH), química sanguínea sencilla con ácido úrico/ 3 parámetros más 1 parámetro (glucosa, urea, creatinina, acido úrico) y examen general de orina (EGO)'
  },
  {
    title: 'Check up hipertensión',
    description: 'Glucosa y perfil de lípidos'
  },
  {
    title: 'Paquete básico 4',
    description: 'Biometría hemática (BH), química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), pruebas de funcionamiento hepático (PFH) y examen general de orina (EGO)'
  },  
  {
    title: 'Paquete básico 5',
    description: 'Biometría hemática (BH), química sanguínea sencilla con ácido úrico/ 3 parámetros más 1 parámetro (glucosa, urea, creatinina, ácido úrico), perfil de lípidos, examen general de orina (EGO)'
  },  
  {
    title: 'Paquete básico 6',
    description: 'Biometría hemática (BH), química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), pruebas de Funcionamiento hepático (PFH), examen general de orina (EGO)'
  },
  {
    title: 'Check up mujeres',
    description: 'Química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), perfil tiroideo perfil hormonal, examen general de orina (EGO)'
  },
  {
    title: 'Paquete completo 1',
    description: 'Biometría hemática (BH), química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), pruebas de funcionamiento hepático (PFH), examen general de orina (EGO), coproparasitoscópico único (CP1)'
  },
  {
    title: 'Check up hombres',
    description: 'Química sanguínea completa (QSC)/ 6 parámetros (glucosa, colesterol, triglicéridos, urea, ácido úrico, creatinina), perfil tiroideo, perfil hormonal, examen general de orina (EGO)'
  },
  {
    title: 'Paquete completo 2',
    description: 'Biometría hemática (BH), química sanguínea sencilla con ácido úrico/ 3 parámetros más 1 parámetro (glucosa, urea, creatinina, ácido úrico), perfil de lípidos, pruebas de funcionamiento hepático (PFH), examen general de orina (EGO), coproparasitoscópico único (CP1)'
  },
  {
    title: 'Check up corazón',
    description: 'Enzimas cardiacas (CPK, CKMB, TGO, DHL), perfil de lípidos, biometría hemática (BH)'
  },
  {
    title: 'Certificados Médicos',
    description: 'Internacionales apegados al anexo 7.19 de la Standards of Training, Certification and Watchkeeping'
  },
  {
    title: 'Atención Bilingüe',
    description: 'Inglés 100% con manejo de personal extranjero.'
  },
  {
    title: 'Outsourcing de salud ocupacional',
    description: ''
  }
]

export default servicesList
