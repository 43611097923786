// Packages
import styled from 'styled-components'
import { Whatsapp } from '@styled-icons/fa-brands/Whatsapp'

export const StyledWAButton = styled.a`
	background-color: #25d366;	
	border-radius: 50px;
	height: 4rem;	
	text-align: center;  
  display: block;
	width: 4rem;
`

export const StyledWAIcon = styled(Whatsapp)`
  color: ${props => props.theme.colors.white};
  margin-top: 0.2rem;
  width: 2.75rem;
`
