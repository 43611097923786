// Packages
import React from 'react'

const settings = {
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,  
  infinite: true,
  nextArrow: <></>,
  prevArrow: <></>,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  swipe: true,
  swipeToSlide: true
}

export default settings
