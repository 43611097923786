const options = [
  {
    label: 'servicios',
    to: '/servicios'
  },
  {
    label: 'contacto',
    to: '/contacto'
  }
]

export default options
