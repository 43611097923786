// Packages
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Instagram } from '@styled-icons/entypo-social/Instagram'
import { Facebook } from '@styled-icons/entypo-social/Facebook'

export const StyledFooter = styled.footer`  
  background: transparent linear-gradient(90deg, rgba(48, 178, 160, 0.8) 0%, rgba(0, 149, 198, 0.8) 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  position: relative;
  width: 100%;
`

export const StyledContent = styled.div`
  padding: 1.5rem 1rem;
`

export const StyledP = styled.p`
  color: #fff;
  text-align: center;
  line-height: 1.3;
`
export const StyledA = styled.a`
  color: #fff;
`

export const StyledLink = styled(Link)`
  color: #fff;
  display: block;  
  margin: 1rem 0;
  margin-right: .5rem;
  text-align: right;
  text-decoration: underline;
  width: 100%;
`

export const StyledStrong = styled.strong`
  font-weight: 700;
`

export const StyledSocialMedia = styled.div`
  margin-left: .5rem;
  width: 100%;
`

export const StyledInstagramIcon = styled(Instagram)`
  color: ${props => props.theme.colors.white};
  margin-left: .5rem;
  width: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    width: 1.75rem;
  }
`

export const StyledFacebookIcon = styled(Facebook)`
  color: ${props => props.theme.colors.white};
  width: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    width: 1.75rem;
  }
`

export const StyledPrivacySocialMediaContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`
